import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SegmentacaoLeadsService } from '../../services/segmentacao-leads.service';
import { SessaoService } from '../../services/sessao.service';
import { ConfirmService } from '../../shared/confirm-modal/confirm-modal-and-service';
import { BsModalRef, BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import { WorkflowService } from '../../services/workflow.service';
import { environment } from "../../../environments/environment";
import { UsuarioService } from '../../services/usuario.service';
import { ConteudoService } from '../../services/conteudo.service';

@Component({
    selector: 'app-segmentacao-leads',
    templateUrl: './segmentacao-leads.component.html',
    styleUrls: ['./segmentacao-leads.component.css']
})
export class SegmentacaoLeadsComponent implements OnInit {
    @ViewChild('modalExecutarFluxo') public modalExecutarFluxo: ModalDirective;
    segmentacoesLeads: any;
    filter: any;
    totalItems: number;
    totalLeads: any = [];
    currentPage: number = 1;
    maxSize: number;
    colexport: any;
    workflows: any;
    fluxo: number;
    segmento_id: number;
    executarNovoFluxo: boolean = false;
    workflow: any = { nome: null, descricao: null };
    qtdeItem:number;
    listItemsPerPage = [5, 10, 15];
    listStatus = [{ name: 'Sim', value: 1 }, { name: 'Não', value: 0 }, { name: 'Todos', value: '' }];
    colunasExport = [];
    modalRef?: BsModalRef;
    config = {
      backdrop: true,
      ignoreBackdropClick: true
    };
    constructor(
        private segmentacaoLeadsService: SegmentacaoLeadsService
        , private modalService: BsModalService
        , private confirmService: ConfirmService
        , private toastr: ToastrService
        , private workflowService: WorkflowService
        , private usuarioService: UsuarioService
        , private conteudoService: ConteudoService
        , private router: Router
        , private sessaoService: SessaoService) { }

    async ngOnInit() {
        this.permissaoExecutarNovoFluxo();
        this.initFiltros();
        this.getSegmentos();

        this.workflowService.getWorkflows().then((w: any) => {
            this.workflows = w;
        });
    }

    initFiltros() {
        let FiltrosSegmentacaoLeads: any = this.sessaoService.get('FiltrosSegmentacaoLeads');
        if (FiltrosSegmentacaoLeads) {
            this.filter = FiltrosSegmentacaoLeads;
        } else {

            this.filter = {
                nome: '',
                usuario: { id: this.sessaoService.getUsuarioId(), nome: this.sessaoService.getUsuarioNome() },
                responsavel_id: '',
                ativo: '',
                page: 1,
                limit: 5
            };
        }
    }

    pageChanged(event: any): void {
        this.filter.page = event.page;
        this.filter.limit = event.itemsPerPage;
        this.getSegmentos();
    }

    async getSegmentos(): Promise<void> {
        this.filter.responsavel_id = this.filter.usuario && this.filter.usuario.id ? this.filter.usuario.id : '';
        this.sessaoService.set('FiltrosSegmentacaoLeads', this.filter);
        let response = await this.segmentacaoLeadsService.get(this.filter).toPromise();
        this.segmentacoesLeads = response.ConteudoCondicoes;
        this.totalItems = response.TotalItems;

        this.segmentacoesLeads.forEach(element => {
            this.atualizarContador(element.filtros_json, element.id);
        });
        let maxSize = Math.ceil(this.totalItems / this.filter.limit);
        this.maxSize = maxSize <= 15 ? maxSize : 15;
    }

    pesquisar() {
        this.filter.page = 1;
        this.currentPage = 1;

        this.getSegmentos();
    }

    editarRegistro(id: number) {
        this.router.navigate(['/segmentacao-leads/form', id]);
    }

    limparFiltros() {
        this.sessaoService.delete('FiltrosSegmentacaoLeads');
        this.initFiltros();
        this.getSegmentos();
    }

    deletar(segmentacao: any = []) {
        this.confirmService.confirm({
            title: 'Exclusão', message: 'Você tem certeza que deseja excluir a segmentação de nome <b>' + segmentacao.nome + '</b> ?'
        }).then(
            () => {
                this.segmentacaoLeadsService.delete(segmentacao.id).subscribe(response => {
                    this.getSegmentos();
                    this.toastr.success('Segmentação de leads excluído com sucesso!');
                });
            },
            () => { });
    }

    excluirLeads(segmentacao: any = []) {
        this.confirmService.confirm({
            title: 'Exclusão', message: 'Utilize essa ação para excluir os leads com falha do banco de dados. Você tem certeza que deseja excluir os Leads da segmentação de nome <b>' + segmentacao.nome + '</b> ?'
        }).then(
            () => {
                this.segmentacaoLeadsService.excluirLeads(segmentacao.id).subscribe(response => {
                    this.toastr.success(response['message']);
                });
            },
            () => { });
    }
    async excluirAtendimentosSasLeadsFalha(segmentacao: any = []) {
        const leads: any = await this.segmentacaoLeadsService.postVisualizacaoLeadsPorQuery({ query: JSON.parse(segmentacao.filtros_json), ignoreLeads: false }).toPromise().then(response => {
            return response.Leads;
        });
        const ids = [];
        leads.forEach(lead => {
            ids.push(lead.id);
        });
        if (!ids.length) {
            this.toastr.warning('Este segmento não possui leads!', 'Atenção');
            return false;
        }
        this.confirmService.confirm({
            title: 'Excluir Atendimento SAS - Leads com Falha', message: 'Essa ação exclui o protocolo de atendimento gerado no SAS, confirmar a exclusão dos protocolos?'
        }).then(
            () => {
                this.conteudoService.excluirAtendimentoSasLeadsFalha(ids).subscribe(response => {
                    this.toastr.success('Aguarde o processamento do workflow para exclusão do atendimento no SAS!');
                }, e => {
                    console.log(e);
                });
            },
            () => { });
    }
    reprocessarLeads(segmentacao: any = []) {
        this.confirmService.confirm({
            title: 'Reprocessar Leads', message: 'Utilize essa ação para reprocessar leads que estão com falha. Você tem certeza que deseja reprocessar os Leads da segmentação de nome <b>' + segmentacao.nome + '</b> ?'
        }).then(
            () => {
                this.segmentacaoLeadsService.reprocessarLeads(segmentacao.id).subscribe(response => {
                    this.toastr.success(response['message']);
                });
            },
            () => { });
    }
    reenviarLeadsTravados(segmentacao: any = []) {
        this.confirmService.confirm({
            title: 'Reenviar Leads Travados', message: 'Utilize essa ação apenas se você perceber que existe leads no status de aguardando ou processando por muito tempo. Confirma o reenvio dos Leads para a fila de processamento, segmentação de nome <b>' + segmentacao.nome + '</b> ?'
        }).then(
            () => {
                this.segmentacaoLeadsService.reenviarLeadsTravadosParaFila(segmentacao.id).subscribe(response => {
                    this.toastr.success(response['message']);
                });
            },
            () => { });
    }
    setColunasExport($event){
        this.colunasExport = $event;
    }
    exportarLeads(id: any, template: any): void {

        this.config['class'] = 'modal-xl';
        this.config['scrollable'] = true;
        this.config['segmentacaoId'] = id;

        this.modalRef = this.modalService.show(template, this.config);
    }
    exportCSV(modalRef: any): void {
        this.segmentacaoLeadsService.exportarLeads(this.config['segmentacaoId'], this.colunasExport.filter(f => f.checked == true).map(m => m.value));
        modalRef.hide();
    }


    atualizarContador(query, i) {
        this.segmentacaoLeadsService.postVisualizacaoLeadsPorQuery({ query: JSON.parse(query), ignoreLeads: true }).toPromise().then(response => {
            this.totalLeads[i] = response.TotalItems;
        });
    }


    openModalExecutarFluxo(obj: any = null) {
        this.segmento_id = null;
        if (obj) {
            this.segmento_id = obj.id;
        }
        this.qtdeItem = this.totalLeads[obj.id];
        this.modalExecutarFluxo.show();
    }

    salvarExecutarNovoFluxo() {

        if (!this.fluxo) {
            this.toastr.warning('Selecione um Fluxo!', 'Atenção');
            return false;
        }
        let params = { segmento_id: this.segmento_id, workflow_id: this.fluxo };
        this.segmentacaoLeadsService.executarNovoFluxo(params).subscribe(response => {
            this.toastr.success(response['message']);
        });
        this.modalExecutarFluxo.hide();
    }
    async permissaoExecutarNovoFluxo() {
        const dados = {
            usuario_id: this.sessaoService.getUsuarioId(),
            projeto: environment.environment.projeto,
            metodo: 'postExecutarNovoFluxo',
            modulo: 'ConteudoCondicoes'
        };
        this.executarNovoFluxo = await this.usuarioService.getIsAuthorized(dados).toPromise();

    }
    public mostraWorkflow(obj) {
        this.workflow.nome = obj.nome;
        this.workflow.descricao = obj.descricao;
    }
}
